import {NavLink} from "react-router-dom";
import {Button} from "@aws-amplify/ui-react";
import {Auth} from "aws-amplify";

function SideMenu() {
  const IS_ACTIVE = "is-active";

  return (
    <div>
      <aside className="menu">
        <p className="menu-label">
          サイドメニュー
        </p>
        <ul className="menu-list">
          <li>
            サンプル
            <ul>
              <li><NavLink className={({isActive}) => (isActive ? IS_ACTIVE : "")} to="/sample1">サンプル1</NavLink></li>
              <li><NavLink className={({isActive}) => (isActive ? IS_ACTIVE : "")} to="/sample2">サンプル2</NavLink></li>
            </ul>
          </li>
          <li><Button onClick={async () => await Auth.signOut()} className={"button is-small is-primary"}>ログアウト</Button></li>
        </ul>
      </aside>
    </div>
  );
}

export default SideMenu;
