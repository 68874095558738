function Footer() {
  const year = (new Date()).getFullYear();
  return (
    <footer className="footer is-small">
      <div className="content ">
        <p>
          COPYRIGHT {year} - <strong>Sorcerer Inc.</strong> | ALL RIGHTS RESERVED
        </p>
      </div>
    </footer>
  );
}

export default Footer;
