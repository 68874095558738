import Footer from "./Footer";
import Header from "./Header";
import {
  BrowserRouter as Router, Navigate,
  Route, Routes
} from "react-router-dom";
import './App.css';
import Sample1 from "./Sample1";
import Sample2 from "./Sample2";
import SideMenu from "./SideMenu";
import { withAuthenticator } from '@aws-amplify/ui-react';

function App() {
  return (
    <Router>
      <Header/>
      <div className="columns">
        <div className="column is-2">
          <SideMenu/>
        </div>
        <div className="column">
          <Routes>
            <Route path="/" element={<Navigate to={'/sample1'} />} />

            <Route path="/sample1" element={<Sample1/>}/>
            <Route path="/sample2" element={<Sample2/>}/>
            <Route element={<NotFound/>}/>
          </Routes>
        </div>
      </div>
      <Footer/>
    </Router>
  );
}

function NotFound() {
  return (
    <div>
      Not Found
    </div>
  );
}

export default withAuthenticator(App);
