function Header() {
  return (
    <header>
      <section className="hero is-small">
        <div className="hero-body">
          <p className="title is-primary">
            <a href="/">React test project</a>
          </p>
        </div>
      </section>
    </header>
  );
}

export default Header;
